import React from "react";
import styled from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const PagePhotoStyled = styled.figure`
  margin-bottom: 1rem;
`;

const PagePhoto = ({ image, caption, className }) => {
  return (
    <PagePhotoStyled>
      <GatsbyImage
        image={getImage(image)}
        alt={caption}
        className={className}
      />

      <figcaption>{caption}</figcaption>
    </PagePhotoStyled>
  );
};

export default PagePhoto;
