import React from "react";
import { Link } from "gatsby";
import { graphql, useStaticQuery } from "gatsby";
import HeroPage from "../components/hero-page";
import Seo from "../components/seo";
import styled from "styled-components";
import PagePhoto from "../components/page-photo";

const StyledRealtorList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 3rem;

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }

  div {
    padding: 1.5rem;
    background-color: var(--palette-very-light-brown);
    border: 1px solid var(--palette-brown);
    border-radius: 8px;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2);

    h2 {
      text-align: center;
    }

    a {
      display: block;
      margin-bottom: 1.1rem;
    }
  }
`;

const Realtors = () => {
  const data = useStaticQuery(graphql`
    query {
      heroImage: file(
        relativePath: { eq: "ronnie-george-9gGvNWBeOq4-unsplash.jpg" }
      ) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      opengraphImage: file(relativePath: { eq: "ronnie-george-9gGvNWBeOq4-unsplash.jpg" }) {
        sharp: childImageSharp {
          image: gatsbyImageData(layout: FIXED, width: 1200)
        }
      }

      houses: file(relativePath: { eq: "homes-road.jpg" }) {
        sharp: childImageSharp {
          image: gatsbyImageData
        }
      }

      houseForSale: file(relativePath: { eq: "house-for-sale.jpg" }) {
        sharp: childImageSharp {
          image: gatsbyImageData
        }
      }
    }
  `);

  return (
    <>
      <Seo
        title="Realtors - Discover PA Six"
        description="Find residential homes, summer lodges and camps, and acreage with breathtaking beauty in North Central Pennsylvania."
        featuredImage={data.opengraphImage.sharp.image}
      />

      <HeroPage
        image={data.heroImage.sharp.fluid}
        title="Realtors"
        caption="Punxsutawney Phil, Jefferson County"
        position="40%"
      />
      <main>
        <section className="page-intro">
          <div className="intro-title">
            <h2>Looking for your dream home?</h2>
            <p>
              Get matched with the very best professionals to help you find your
              dream home or mountain lodge.
            </p>
          </div>
          <div className="intro-text">
            <p>
              Start here to find residential homes, summer lodges and camps, and
              acreage with breathtaking beauty. Our full service real estate
              firms and experts are unmatched in providing personalized and
              customized sales, appraisals or rentals to make your move or
              vacation to PA Six a smooth transition and experience.
            </p>
          </div>
        </section>

        <section>
          <StyledRealtorList>
            <div>
              <h2>Cameron County</h2>

              <Link to="https://www.crownrealtypa.com/">
                Crown Realty Services Inc.
              </Link>

              <Link to="https://www.cameroncountyrealestate.com/">
                Good Real Estate
              </Link>
            </div>

            <PagePhoto image={data.houses.sharp.image} />

            <div>
              <h2>Clearfield County</h2>
              <Link to="https://aswrealtors.com/">
                Amon, Shimmel & Walsh Realtors
              </Link>

              <Link to="https://www.gobrightstone.com/">
                Brightstone Realty Group, LLC
              </Link>

              <Link to="https://burleigh116.wixsite.com/homes/listings">
                Burleigh Real Estate
              </Link>

              <Link to="https://www.duboisarearealestate.com/">
                Coldwell Banker
              </Link>

              <Link to="https://www.hofferrealty.com/Home">Hoffer Realty</Link>
              <Link to="https://nelsonrealtypa.com/">Nelson Realty</Link>
              <Link to="https://duboistreasurelake.com/">
                Re/Max Select Group
              </Link>
              <Link to="https://www.danielkolody.com/">
                Daniel Kolody $ Associates, LLC
              </Link>
            </div>

            <div>
              <h2>Jefferson County</h2>
              <Link to="https://nelsonrealtypa.com/">Nelson Realty</Link>
              <Link to="https://duboistreasurelake.com/">
                Re/Max Select Group
              </Link>
              <Link to="https://www.thepreferredrealty.com/">
                Berkshire Hathaway Home Services
              </Link>
              <Link to="https://www.danielkolody.com/">
                Daniel Kolody $ Associates, LLC
              </Link>
              <Link to="https://forrestpropertysolutions.com/">
                Forrest Property Solutions
              </Link>
              <Link to="https://grayandcompany.net/gac/homes/">
                Gray and Company Real Estate
              </Link>
              <Link to="http://www.allemangrealty.com/">
                Howard Hanna Allemang Realty
              </Link>
              <Link to="http://www.laurelrealty.biz/">Laurel Realty</Link>
              <Link to="https://pro-marketrealty.com/">Pro-Market Realty</Link>
              <Link to="http://www.zgmatson.com/about.html">
                Z.G. Matson Realty
              </Link>
            </div>

            <PagePhoto image={data.houseForSale.sharp.image} />

            <div>
              <h2>Elk County</h2>
              <Link to="https://duboistreasurelake.com/">
                Re/Max Select Group
              </Link>
              <Link to="https://www.coldwellbanker.com/">Coldwell Banker</Link>
              <Link to="https://www.elkcountyre.com/">
                Anderson & Kime Realty Services
              </Link>
              <Link to="https://www.elkcountyre.com/">
                Elk County Real Estate
              </Link>
              <Link to="https://www.howardhanna.com/Office/Detail/hhnsrgsaintmarys/004647">
                Howard Hanna
              </Link>
            </div>

            <div>
              <h2>McKean County</h2>
              <Link to="https://www.coldwellbanker.com/">Coldwell Banker</Link>
              <Link to="https://www.era.com/era-team-vp-real-estate-1787c/bradford-office-66036d">
                ERA Team VP Real Estate
              </Link>
              <Link to="https://www.howardhanna.com/Office/Detail/hhpbradford/004654">
                Howard Hanna Bradford
              </Link>
              <Link to="https://www.realliving.com/real-estate-experts">
                Real Living Real Estate
              </Link>
              <Link to="https://www.zimmermanrealtyllc.com/">
                Zimmerman Realty LLC
              </Link>
            </div>

            <div>
              <h2>Potter County</h2>

              <Link to="https://fourseasonsre.com/">
                Four Seasons Real Estate
              </Link>

              <Link to="https://www.howardhanna.com/Office/Detail/hhrpcoudersport/004672">
                Howard Hanna Coudersport
              </Link>

              <Link to="https://www.pinecreekrealestate.com/">
                Pine Creek Real Estate
              </Link>

              <Link to="https://reallyruralre.com/">
                Really Rural Real Estate
              </Link>

              <Link to="https://www.trailsendrealty.com/">
                Trail's End Realty
              </Link>
            </div>
          </StyledRealtorList>
        </section>
      </main>
    </>
  );
};

export default Realtors;
